<template>
  <el-form ref="form" :model="form" label-width="80px">
    <el-form-item label="标签" v-if="tags.length > 0">
      <el-tag
        v-for="tag in tags"
        :key="tag.id"
        closable
        @close="handleClose(tag)"
        class="m-r-10"
      >
        {{ tag.name }}
      </el-tag>
    </el-form-item>

    <el-form-item label="标签名字">
      <el-input v-model="name"></el-input>
    </el-form-item>
    <!-- <el-form-item label="标签简介">
      <el-input type="textarea" v-model="form.desc"></el-input>
    </el-form-item> -->

    <el-form-item>
      <el-button type="primary" @click="onSubmit">立即创建</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { lableAdd, getAllLable, deleteLable } from "@/api/lable";
export default {
  data() {
    return {
      name: "",
      tags: [],
    };
  },
  mounted() {
    this.getlable();
  },
  methods: {
    getlable() {
      console.log(1);
      getAllLable().then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.tags = res.data;
        }
      });
    },
    handleClose(item) {
      console.log(item);
      deleteLable({ id: item.id }).then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.getlable();
        }
      });
    },
    onSubmit() {
      lableAdd({ name: this.name }).then((res) => {
        console.log(res);
        this.getlable();
      });
      console.log("submit!");
    },
  },
};
</script>